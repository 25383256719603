@import "bootstrap-icons/font/bootstrap-icons.css";
@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@400;700&display=swap");

@font-face {
  font-family: "Gotham";
  src: url("assets/fonts/Gotham-Font/GothamMedium.ttf");
  font-weight: normal;
  font-style: normal;
}

/* Application-wide Styles */

h1 {
  color: #369;
  font-family: Arial, Helvetica, sans-serif;
  /* font-weight: 500; */
}
h2,
h3 {
  color: #444;
  font-family: Arial, Helvetica, sans-serif;
  /* font-weight: lighter; */
}

a {
  color: inherit;
  text-decoration: none;
}

a:visited {
  color: inherit;
}

/* Page template (body, page, header) begining */

body {
  margin: 0;
}

/* .body is different than body above */
.body {
  display: flex;
  padding-inline: 10rem;
}

.page {
  width: 100%;
}

.header {
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 1200px) {
  /* Responsive */
  .body {
    padding-inline: 2rem;
  }
}

/* Page template (body, page, header) end */

body,
input[type="text"],
button {
  color: #333;
  font-family: Cambria, Georgia, serif;
}

.button {
  background-color: #e3e3e3;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  color: black;
  font-size: 1.2rem;
  padding: 0.5rem 1rem;
  margin: 1rem 0;
  font-family: Arial, Helvetica, sans-serif;
}
.button:hover {
  background-color: black;
  color: white;
}
.button:disabled {
  background-color: #eee;
  color: #aaa;
  cursor: auto;
}

.card {
  width: 100%;
  border: 1px solid rgb(187, 187, 187);
  border-radius: 1rem;
  margin-bottom: 2rem;
  padding: 0 2rem 1.5rem;
}

.action-buttons-item {
  margin-left: 1rem;
  padding: 0.5rem;
  font-size: 1.2rem;
  align-self: center;
  border-radius: 0.5rem;
}

.action-buttons-item:hover {
  cursor: pointer;
  background-color: #d3d3d3;
}

/* Color cohesion in application */
.green-state {
  background-color: rgb(12, 173, 0);
}

.orange-state {
  background-color: rgb(255, 123, 0);
}

.red-state {
  background-color: rgb(218, 0, 0);
}

.ready-to-publish {
  color: rgb(12, 173, 0);
}

.not-ready-to-publish {
  color: rgb(218, 0, 0);
}

.truncate-one-line {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  white-space: normal;
}

.truncate-two-lines {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  white-space: normal;
}

.single-line {
  white-space: nowrap;
  /* overflow: hidden; */
}

.blue-color {
  color: #369;
}

.red-font {
  color: red;
}

.char-count {
  display: block;
  text-align: right;
  margin-top: 0.2rem;
}

/* Margin section start */

.no-margin {
  margin: 0;
}

.margin-left-small {
  margin-left: 0.5rem;
}

.margin-top-medium {
  margin-top: 2.5rem;
}

/* Margin section end */

/* PrimeNg CSS elements below */

:host ::ng-deep .p-inputtext {
  color: #369;
  margin-top: 0.2rem;
  height: 2rem;
}

/* PrimeNg CSS elements above */

/* everywhere else */
* {
  font-family: Arial, Helvetica, sans-serif;
}
